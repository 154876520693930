import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import styled from "styled-components/macro";
import { RootState } from "../../core/store";
import { getCurrentEvent } from "../../core/store/events/reducers";
import { IEvent } from "../../core/store/events/types";
import { getPlayerData } from "../../core/store/player/reducers";
import { IPlayer } from "../../core/store/player/types";
import Title from "../Title";
import EventInfo from "./EventInfo";
import EventStatusBar from "./EventStatusBar";
import PlayerAvailability from "./PlayerAvailability";
import StatusDreamTeam from "./StatusDreamTeam";
import TransfersPanel from "./TransfersPanel";
import Alert from "../Alert";

const StatusWrapper = styled.div`
  max-width: 1220px;
  margin: 0 auto;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    padding-inline-end: ${({ theme }) => theme.space[2]};
    padding-inline-start: ${({ theme }) => theme.space[2]};
  }
`;

const StatusCols = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    display: flex;
    justify-content: space-between;
  }
`;

const StatusMain = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex: 0 0 66.66%;
  }
`;

const StatusSecondary = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex: 0 0 30%;
  }
`;

interface IPropsFromState {
  now: IEvent | null;
  player: IPlayer | null;
}

const Status: React.FC<IPropsFromState> = ({ now, player }) => {
  const { t } = useTranslation();

  if (!now || !player) {
    return null;
  }

  return (
    <StatusWrapper>
      <Box my={3}>
        <Alert type="warning">
          <h3>{t("Downtime.Heading", "Game Update Notice")}</h3>
          <p>
            {t(
              "Downtime.Message",
              "The RSL Fantasy game will be in update mode and not accessible from Monday to Wednesday. Once the game is back online, you will have to reset your password by selecting 'Forgot Password' when you log in."
            )}
            {t("Downtime.SignOff", " Thank you for your understanding!")}
          </p>
        </Alert>
      </Box>
      <Box mt={4} mb={2} px={2}>
        <p>
          {t(
            "status.loggedIn",
            "You are logged in as {{ first_name }} {{ last_name }}",
            {
              first_name: `${player.first_name}`,
              last_name: ` ${player.last_name}`,
            }
          )}
        </p>
        <Title>
          {t("status.title", " {{ name }} Status ", {
            name: now.name,
          })}
        </Title>
      </Box>
      <Box mb={4}>
        <EventStatusBar />
      </Box>
      <Box mb={4}>
        <EventInfo />
      </Box>
      <Box mb={4}>
        <StatusDreamTeam />
      </Box>

      <StatusCols>
        <StatusMain>
          <Box mb={4}>
            <TransfersPanel />
          </Box>
          <Box mb={4}>
            <TransfersPanel isOut={true} />
          </Box>
        </StatusMain>
        <StatusSecondary>
          <Box mb={4}>
            <PlayerAvailability />
          </Box>
        </StatusSecondary>
      </StatusCols>
    </StatusWrapper>
  );
};

const mapStateToProps = (state: RootState): IPropsFromState => ({
  now: getCurrentEvent(state),
  player: getPlayerData(state),
});

export default connect(mapStateToProps)(Status);
