import { hiDPI, hideVisually, size } from "polished";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components/macro";
import { ReactComponent as FantasyLogo } from "../img/fantasy-logo.svg";
import { ReactComponent as SponsorLogo } from "../img/sponsor-logo.svg";
import { ReactComponent as GBFlag } from "../img/flags/gb.svg";
import { ReactComponent as SAFlag } from "../img/flags/sa.svg";
import headerPattern from "../img/header-pattern-bg.svg";
import playerComposite1x from "../img/player-composite-@1x.png";
import playerComposite2x from "../img/player-composite-@2x.png";
import { ReactComponent as ClientLogo } from "../img/spl-logo.svg";
import { ReactComponent as Tagline } from "../img/tagline.svg";
import { isRTL } from "../utils/locale";
import { getIsWebView } from "../utils/webView";
import Navigation from "./nav/Navigation";
import { Wrapper } from "./Layout";

const direction = isRTL() ? `to left` : `to right`;

const GameHeaderOuter = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 140px;
  background: url(${headerPattern}),
    linear-gradient(
      ${direction},
      ${({ theme }) => theme.colors.primary} 65%,
      ${({ theme }) => theme.colors.green} 65% 100%
    );

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    height: 230px;
    background: url(${headerPattern}),
      linear-gradient(
        ${direction},
        ${({ theme }) => theme.colors.primary} 65%,
        ${({ theme }) => theme.colors.green} 65% 100%
      );
  }
`;

const GameHeaderInner = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 90%;
  max-width: 1220px;
`;

const PlayerImage = styled.div`
  position: absolute;
  bottom: 0;
  left: ${isRTL() && `-15px`};
  right: ${!isRTL() && `-15px`};
  width: 100%;
  height: 130px;
  background-image: url(${playerComposite1x});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: ${isRTL() ? `100% 0` : `100% 0`};
  transform: ${isRTL() ? `scaleX(-1)` : `scaleX(1)`};

  ${hiDPI(2)} {
    background-image: url(${playerComposite2x});
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    left: ${isRTL() && `0px`};
    right: ${!isRTL() && `0px`};
    height: 235px;

    ${hiDPI(2)} {
      background-image: url(${playerComposite2x});
    }
  }
`;

const SplLink = isRTL()
  ? "https://www.spl.com.sa/ar"
  : "https://www.spl.com.sa/en";

const FlagList = styled.ul`
  position: absolute;
  top: 15px;
  left: ${!isRTL() && `0`};
  right: ${isRTL() && `0`};
  display: flex;
  gap: 1rem;
  margin: 0;
  padding: 0;
  list-style-type: none;
  z-index: 1;
`;

const FlagItem = styled.li`
  display: inherit;
  padding-inline-end: 1rem;
  vertical-align: middle;
  line-height: 1;

  :not(:last-child) {
    border-inline-end: 1px solid white;
  }
`;

const FlagLink = styled.a`
  display: inherit;
`;

const FlagStyles = css`
  ${size(13, 20)}

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    ${size(17, 28)}
  }
`;

const StyledSAFlag = styled(SAFlag)`
  ${FlagStyles}
`;

const StyledGBFlag = styled(GBFlag)`
  ${FlagStyles}
`;

const HeaderLogo = styled.div`
  z-index: 1;
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 40px;

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    gap: 20px;
    margin-top: 60px;
  }
`;

const ClientLogoStyles = css`
  ${size(41, 53)}

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    ${size(98, 110)}
  }
`;

const StyledClientLogo = styled(ClientLogo)`
  ${ClientLogoStyles}
`;

const FantasyLogoStyles = css`
  ${size(27, 90)}

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    ${size(40, 210)}
  }
`;

const GameTitle = styled.h1`
  margin: 0;

  @media (max-width: ${(props) => props.theme.breakpoints[3]}) {
    height: 27px;
  }
`;

const StyledFantasyLogo = styled(FantasyLogo)`
  ${FantasyLogoStyles}
  margin: 0;
`;

const TagLineWrap = styled.div`
  @media (max-width: ${(props) => props.theme.breakpoints[3]}) {
    ${hideVisually()};
  }
`;

const TitleSponsorWrap = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${(props) => props.theme.breakpoints[3]}) {
    margin-top: ${({ theme }) => theme.space[4]};
  }
`;

const SponsorWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const Sponsor = styled(SponsorLogo)`
  ${size(15, 30)}
  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    ${size(15, 40)}
  }
`;

const SponsorTagline = styled.h2`
  margin: 0;
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSizes[0]};

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    font-size: ${({ theme }) => theme.fontSizes[2]};
  }
`;

const GameHeader = () => {
  const { t } = useTranslation();

  if (getIsWebView()) {
    return (
      <Wrapper>
        <Navigation />
      </Wrapper>
    );
  }

  return (
    <GameHeaderOuter>
      <GameHeaderInner>
        <FlagList>
          <FlagItem>
            <FlagLink href="https://fantasy.spl.com.sa/">
              <StyledSAFlag />
            </FlagLink>
          </FlagItem>
          <FlagItem>
            <FlagLink href="https://en.fantasy.spl.com.sa/">
              <StyledGBFlag />
            </FlagLink>
          </FlagItem>
        </FlagList>
        <PlayerImage />
        <HeaderLogo>
          <a href={SplLink} target="_blank" rel="noreferrer">
            <StyledClientLogo
              title={t("gameHeader.homeLink", "Go to home page")}
            />
          </a>
          <TitleSponsorWrap>
            <GameTitle>
              <StyledFantasyLogo title="RSL Fantasy" />
            </GameTitle>
            <SponsorWrap>
              <SponsorTagline>
                {t("gameHeader.sponsorTagline", "Presented by")}
              </SponsorTagline>
              <Sponsor />
            </SponsorWrap>
          </TitleSponsorWrap>

          <p>
            <TagLineWrap>
              <Tagline title={t("gameHeader.tagline", "Let's Play")} />
            </TagLineWrap>
          </p>
        </HeaderLogo>
        <Navigation />
      </GameHeaderInner>
    </GameHeaderOuter>
  );
};

export default GameHeader;
